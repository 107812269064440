import { render, staticRenderFns } from "./VerifiedSupplier.vue?vue&type=template&id=ecb0c4b2&scoped=true&"
import script from "./VerifiedSupplier.vue?vue&type=script&lang=js&"
export * from "./VerifiedSupplier.vue?vue&type=script&lang=js&"
import style0 from "./VerifiedSupplier.vue?vue&type=style&index=0&id=ecb0c4b2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ecb0c4b2",
  null
  
)

export default component.exports